.CenterFlex{
    display: flex;
    flex-direction: column;
    /* position:relative;
    padding-top: 1rem;
    transition: all 300ms ease; */
}

.centered {
    height: 100vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .photo{
    width: 60%; 
    height: 60%; 

  }

/* @media only screen and (max-width: 1026px) { */
    .photo2{
        /* position: absolute; */
      width: 494px;
      height: 673px;
      left: 716px;
      top: 176px;
      border-radius: 0px 10px 10px 0px;
      }
/* } */

.inlineBlocker{
  display: inline-block;

}

.blueishBackground{
  background:#CDD6E8
}

.grayishBackground{
  background:#F1F2F4
}

.headerPadding{
  padding-top: 2rem;
}

.indentStandardPadding{
  padding-left: 2rem;
  padding-right: 2rem;
}

.fullWidth{
  width: 100%;
}

.right {
  display: flex;
  justify-content: right;
  align-items: right;
  text-align: right;
}

.cardWidth2{
  width:576px;
}

.TwoRemPadding{
  padding:2rem;
}

.width800{
  width: 800px;
}