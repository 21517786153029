.PublicNavBar{
    display: flex;
    flex-direction: column;
    position:relative;
    /* padding-top: 1rem; */
    transition: all 300ms ease;
}

.imageLogo{
max-width: 68px;
width: 10%;
display: block;
margin-left: 0;
margin-right: 1rem;
margin-bottom: 0;
margin-top: 0;

}

.menu{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

}

.menuItem{
    display: flex;
    align-items: center;

}

.navBarItems{
    align-content: center;
    align-items: center;
}

.halfRemMargin{
    margin-top: 0.5rem;
}

