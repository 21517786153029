.customFooter{
  text-align: center;
    background-color: #2e4942;
    /* color: #2e4942; */
    margin-bottom: -1000px; 
    content: "";
    display: block;
    min-height: 100%;
    overflow: auto;

}
.whiteText{
  color: white;
}
