.CenterFlex{
    display: flex;
    flex-direction: column;
    /* position:relative;
    padding-top: 1rem;
    transition: all 300ms ease; */
}

.centered {
    height: 100vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .photo{
    width: 60%; 
    height: 60%; 

  }

/* @media only screen and (max-width: 1026px) { */
    .photo2{
        /* position: absolute; */
      width: 494px;
      height: 673px;
      left: 716px;
      top: 176px;
      border-radius: 0px 10px 10px 0px;
      }
/* } */

.inlineBlocker{
  display: inline-block;

}

/* width */
::-webkit-scrollbar {
  /* width: 10px; */
  box-shadow: 0 0 2px #D1D5DB;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  /* box-shadow: 0 0 2px #D1D5DB; */
  /* border-radius: 10px; */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #D1D5DB; 
  /* border-radius: 10px; */

  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #D1D5DB; 
}

.courseBoxList{
  height:100px;
  width:350px;
  border:1px solid #D1D5DB;
  border-radius: 4px;
  font-size:16px;
  overflow:auto;
  

}

.activelySelectedItem{
  background-color: #046AB4;
  width: 100%;
  text-align: left;
  color: white;
  padding-left: 10px;
}

.notActivelySelectedItem{
  background-color: white;
  width: 100%;
  text-align: left;
  color: black;
  padding-left: 10px;
}

button.notActivelySelectedItem:hover {
  background-color: #E0F2FE;
  width: 100%;
  text-align: left;
  color: black;
  padding-left: 10px;
}

.formControlCustom{
      /* display: block; */
      width: 50%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
       /* color: #212529; */
      /* background-color: #fff; */
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 0.25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.LanguageBorder{
  border: 1px;
  border-style: solid;
  border-radius: 4px;
}

.collegeProfessionBorder{
  border: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
}