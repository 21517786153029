.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.AppGlass{
    background-color: aqua;
    height: 100%;
    text-align: center;
}

/* .Main{
  width: 100%;
  margin: 0%;
  padding: 0%;
} */

.logo1{
  max-width: 1000px;
  width: 40%;
  height: 40%;

  justify-content: center;

}

.logo2{
  max-width: 1000px;
  width: 26%;
  height: 26%;
  justify-content: center;

}

.doubleImageShowOff{

  background-color: #ECECEC;
  padding: 0% ;
  margin: 0% ;
  display: inline-block;
  width: 100%
}

.doubleImageShowOffInner{
  display: flex;
  justify-content: center;
}

.freeTutoringSpan{
  background-color: #5793CE;
  padding: 0% ;
  margin: 0% ;
  display: inline-block;
}

.freeTutoringSpanInner{
  display: flex;
  justify-content: center;
}

.boldme{
  font-weight: bold;
}

.freeTutoringSpanText{
  text-align: center;
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.freeTutoringSpanText2{
}

.ProvidingFreeTutoringText{
  font-size: 50px;
}

.whiteTexty{
color:white
}

.lineHeight1-6{
  line-height: 2;
}

.howItWorksSpan{
  background-color: white;
  padding: 0% ;
  margin: 0% ;
  display: inline-block;
}

.howItWorksSpanInner{
  display: flex;
  justify-content: center;
}

.howItWorksSpanText{
  text-align: center;
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.howItWorksSpanTextMiddle{
  text-align: center;
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.howItWorksSpanTextLeft{
  text-align: left;
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.howItWorksSpanTextRight{
  text-align: right;
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.howItWorksSpanText2{
}

.darkBlueText01{
  color: #173d75
}

.HIWgrayTEXT01{
  color: #54595f
}

#carouselHolder{
  /* margin-left: 20%;
  margin-right: 20%; */
  width: 65%;
}

.greenBackground{
  background-color: #22B573;
  background: #22B573;
}

#carouselTextHolder{
  padding: 0%;
  margin: 0%;
  width: 100%;
  height: 200%;
}

#carouselText{
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

.whiteText{
  color: white;
}

#testimonialRevierStyling{
  text-align: right;
  padding-right: 10%;
}

#bottomMargin30px{
  margin-bottom: 30px;
}

.iconPadding{
  padding-top: 0.8rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 1rem;
}

.employess {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}