.CenterFlex{
    display: flex;
    flex-direction: column;
    /* position:relative;
    padding-top: 1rem;
    transition: all 300ms ease; */
}

.centeredSomeContent {
    display: flex;
    justify-content: center;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
