body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Karla&display=swap'); */

/* body {
  font-family: Karla;
} */

/* .content {
  max-width: 1280px;
  overflow-x: hidden;
  margin: 0 auto;
} */

#card {
  height: 100%;
  display: inline-block;
  flex-direction: column;
  max-width: 10rem;
  position: relative;
  border: 0px solid rgba(255,255,255,0);
  justify-content: center;
}

/* .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .card-header span {
  color: #2d3748;
} */

#card-image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  /* : center; */

}

/* .card button {
  display: inline-block;
  background-color: #14c8eb;
  color: #fff;
  border: none;
  padding: 1.5em 1.6em;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: background-color 0.5s ease-out;
} */

/* .card button:focus {
  outline: none;
}

.card button:hover {
  background-color: #0ea8c7;
} */

/* .text-info {
  padding: 1rem 1.5rem;
  margin-bottom: 2.5rem;
} */

/* .controls {
  display: flex;
  justify-content: end;
  margin-bottom: 1.5rem;
}

.controls button {
  width: 40px;
  height: 40px;
  border: 0;
  background-color: #14c8eb;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
}

.controls button:hover {
  background-color: #0ea8c7;
}

.controls button:nth-child(1) {
  margin-right: 2rem;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .cardCustom {
    background-color: theme('colors.white');
    border-radius: 4px;
    padding: 48px;
    box-shadow: theme('boxShadow.xl');
    width:500px
  }

  .defaultPrimary1{
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 4px;

    /* Primary/Action Primary */

    background: #046AB4;
    border-radius: 4px;
  }
  .loginButton{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    gap: 4px;

    /* width: 404px;
    height: 52px; */

    /* Primary/Action Primary */
    background: #046AB4;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    }
    .loginButtonText{
      color: white;
      /* font-family: 'Open Sans'; */
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
    }
  .resendEmailButton{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    gap: 4px;

    color: #046AB4;
    background: #fff;
    border-radius: 4px;


    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .padding48vert{
    padding: 48px 0px
  }
  .padding48x{
    padding: 0px 48px
  }

  .signupBackground{
    background: #E5E5E5;
  }

  .rightradius4{
    border-radius: 0px 4px 4px 0px;
  }
  .borderradius4{
    border-radius:4px;
  }
  /* ... */
}